import * as React from 'react';
import classNamesFn from 'classnames';
import { DefaultCompPlatformProps } from '@wix/editor-elements-types';
import { FreemiumBannerMobileProps as FreemiumBannerMobileTBProps } from '@wix/thunderbolt-components';
import { DownloadLogo, WixLogo } from '../../common/assets/logos';
import Link from '../../../Link/viewer/Link';
import { TestIds } from '../../common/constants';
import * as translations from './constants';
import style from './style/FreemiumBannerMobile.scss';

const defaultDirection = 'ltr';

export type FreemiumBannerMobileProps = DefaultCompPlatformProps &
  FreemiumBannerMobileTBProps;

const FreemiumBannerMobile: React.FC<FreemiumBannerMobileProps> = props => {
  const {
    id = 'WIX_ADS',
    translate,
    useOverlay = false,
    href = '',
    classNames = [defaultDirection],
  } = props;

  const anchorClassNames = classNamesFn(
    ...classNames.map(name => style[name]),
    style.mobileTop,
  );

  const translatedBannerText = translate!(
    translations.NAMESPACE,
    translations.TEXT_KEY,
    translations.TEXT_DEFAULT,
  );
  const [textBeforeLogo, textAfterLogo] = translatedBannerText.split(
    translations.wixLogoPlaceHolder,
  );

  return (
    <div id={id} className={`${style.mobile} ${style.freemiumBanner}`}>
      {useOverlay ? (
        <div data-testid={TestIds.overlay} className={anchorClassNames} />
      ) : (
        <Link
          className={anchorClassNames}
          href={href}
          target="_blank"
          rel="nofollow"
        >
          <span className={style.content}>
            {textBeforeLogo}
            <WixLogo rootClass={style.wixLogo} dotClass={style.dot} />
            {textAfterLogo}
            <DownloadLogo className={style.downloadLogo} />
          </span>
        </Link>
      )}
    </div>
  );
};

export default FreemiumBannerMobile;
